import { timeToAppFormat } from '../utils'
import $ from 'jquery'
export default {
  props: {
    startField: {
      type: String,
      required: true
    },
    statusField: {
      type: String,
      required: true
    },
    quoteOnly: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    setStatus(mode) {
      $(this.statusField).val(mode)
    },
    setValue(value) {
      $(this.startField).val(value)
    },
    onStartPicked(newTime, status) {
      var newStart = timeToAppFormat(newTime)
      this.setValue(newStart)
      this.setStatus(status)
      this.$emit('start-picked', Date.parse(newTime).toString('MMMM dS, yyyy h:mm tt'), newStart, status)
    },
  },
  watch: {
    quoteOnly(newValue, oldValue) {
      console.log("start_and_status_input_interface_mixin.js QuoteOnly changed ", oldValue, ' -> ', newValue)
      if (newValue) {
        this.setValue('')
        this.setStatus('quote_only')
      } else {
        this.setStatus('')
      }
    }
  }
}
